body{
	background-color: #f5f5f5;
}

.ar.header{
	height: 50px;
	background-color: rgba(243,116,53,1);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;

	img{
		height: 25px;
	}
}
.fr.countdown.message{
	text-align: center;
	max-width: 200px;
	margin: auto;
	padding: 10px;
	margin-bottom: 10px;
	.header{
		margin-bottom: 0;
	}
}
.ar.ticket{
	$IMAGE_SIZE: 80px;
	.info{
		display: flex;
		align-items: center;
		img{
			width: $IMAGE_SIZE;
			height: $IMAGE_SIZE;
			border-radius: 8px;
		}
		.data{
			margin-left: 15px;
			.meta{
				color: gray;
			}
			.event{
				font-size: 20px;
				line-height: 24px;
				font-weight: bold;
			}
		}
	}
	.buttons{
		margin-top: 10px;
		display: flex;
		justify-content: flex-end;
		.fr.button{
			margin-left: 5px;
			min-width: 150px;
			&:first-child{
				margin-left: 0;
			}
		}
		> a{
			height: 40px;
		}
		> img{
			width: 150px;
			height: 40px;
			display: inline-flex;
		}
		@media screen and (max-width: 500px){
			margin-top: 15px;
			justify-content: center;
		}
	}
}

.fr.all.button{
	display: block;
	margin: auto;
	margin-bottom: 20px;
	width: 250px;
}

.fr.groupper.tickets{
	// .head{
	// 	margin-bottom: 10px;
	// }
	.divider{
		border-color: #e1e1e1;
	}
}